const sortPlatforms = (x, y) => {
  const specialValues = {
    a_plataforma_nao_esta_na_lista: 1,
    ainda_nao_decidi_a_plataforma: 2,
    desenvolvimento_proprio: 3,
    uso_link_de_pagamento: 4,
  }

  const getXValueOrder = specialValues[x.value] || Infinity
  const getYValueOrder = specialValues[y.value] || Infinity

  if (getXValueOrder !== Infinity || getYValueOrder !== Infinity) {
    return getXValueOrder - getYValueOrder
  }

  const startsWithNumberX = /^\d/.test(x.value)
  const startsWithNumberY = /^\d/.test(y.value)

  if (startsWithNumberX || startsWithNumberY) {
    return startsWithNumberX ? 1 : -1
  }

  return x.value.localeCompare(y.value)
}

export default sortPlatforms
