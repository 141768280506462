import PropTypes from 'prop-types'
import { toLower } from 'ramda'
import React, { useState } from 'react'
import { Description, Form, RadioGroup, TextInput } from '../../components/Form'
import TermsOfUse from '../../components/TermsOfUse'
import * as S from './styles'
import { isEmail, isRequired } from './validations'

const CreateAccount = ({ onSubmit, registerData, t }) => {
  const [insertedValidEmail, setInsertedValidEmail] = useState(false)
  const [insertedValidName, setInsertedValidName] = useState(false)

  const onEmailValidChange = isValid => {
    if (isValid) {
      setInsertedValidEmail(isValid)
    }
  }

  const onNameValidChange = isValid => {
    if (isValid) {
      setInsertedValidName(isValid)
    }
  }

  return (
    <Form
      data={registerData}
      disableSubmit={!insertedValidEmail || !insertedValidName}
      onSubmit={onSubmit}
      t={t}
    >
      <S.Row>
        <TextInput
          name="first_name"
          onInputValidChange={onNameValidChange}
          placeholder={t('pages.create_account.first_name_placeholder')}
          title={t('pages.create_account.first_name')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          name="last_name"
          onInputValidChange={onNameValidChange}
          placeholder={t('pages.create_account.last_name_placeholder')}
          title={t('pages.create_account.last_name')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          name="email"
          onInputValidChange={onEmailValidChange}
          parse={toLower}
          placeholder="email@email.com.br"
          title={t('pages.create_account.email')}
          type="email"
          validations={[isRequired(t), isEmail(t)]}
        />
        <Description text={t('pages.create_account.email_description')} />
      </S.Row>
      <RadioGroup
        name="isPartner"
        options={[
          {
            name: 'Sim',
            value: 'Sim',
          },
          {
            name: 'Não',
            value: 'Não',
          },
        ]}
        title={t('pages.create_account.is_partner')}
        validations={[isRequired(t)]}
      />
      <TermsOfUse t={t} />
    </Form>
  )
}

CreateAccount.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  registerData: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

CreateAccount.defaultProps = {
  registerData: {},
}

export default CreateAccount
