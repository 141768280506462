import PropTypes from 'prop-types'
import { compose, equals, includes } from 'ramda'
import React from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { translate } from 'react-i18next'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { useData } from '../context'

import FormBackground from '../components/FormBackground'
import Pardot from '../vendor/pardot'
import ReactGTag from '../vendor/reactGTag'
import withRedirect from '../vendor/withRedirect'
import BankAccountDetails from './BankAccountDetails'
import CompanyAddress from './CompanyAddress'
import CompanyDetails from './CompanyDetails'
import Confirmation from './Confirmation'
import PartnerAddress from './PartnerAddress'
import PartnerDetails from './PartnerDetails'
import WaitingConfirmation from './WaitingConfirmation'
import Welcome from './Welcome'

const enhanced = compose(withRouter, translate(), withRedirect)

const nonRedirectablePaths = ['/boas-vindas-ao-pagarme', '/confirmar']

const isRoot = equals('/')

const Root = ({ history, location, redirect, t }) => {
  ReactGTag.usePageViews('UA-44419105-1')
  Pardot.usePageViews()

  const { data } = useData()
  const { hash, pathname } = location

  useBeforeunload(
    () =>
      pathname !== '/boas-vindas-ao-pagarme' &&
      pathname !== '/verifique-seu-email' &&
      pathname !== '/confirmar' &&
      t('beforeunload_warning'),
  )

  const isRedirectablePath =
    !includes(pathname, nonRedirectablePaths) && !includes('#/confirmar', hash)

  const shouldRedirect = (isRoot(pathname) || !data.email) && isRedirectablePath

  const uri = {
    pathname: '/boas-vindas-ao-pagarme',
    search: encodeURI(location.search),
  }

  if (shouldRedirect) {
    return <Redirect to={uri} />
  }

  const renderFormPage = Component => props => {
    const { pathname: path } = props.location

    return (
      <FormBackground history={history} path={path} t={t}>
        <Component redirect={redirect} {...props} t={t} />
      </FormBackground>
    )
  }

  return (
    <Switch>
      <Route
        component={renderFormPage(Welcome)}
        path="/boas-vindas-ao-pagarme"
      />
      <Route
        component={renderFormPage(CompanyDetails)}
        path="/dados-da-empresa"
      />
      <Route
        component={renderFormPage(PartnerDetails)}
        path="/dados-do-representante"
      />
      <Route
        component={renderFormPage(PartnerAddress)}
        path="/endereco-do-representante"
      />
      <Route
        component={renderFormPage(CompanyAddress)}
        path="/endereco-da-empresa"
      />
      <Route
        component={renderFormPage(BankAccountDetails)}
        path="/dados-bancarios"
      />
      <Route component={WaitingConfirmation} path="/verifique-seu-email" />
      <Route component={Confirmation} path="/confirmar" />

      <Route
        exact
        path="/"
        render={({ location: l }) => <Redirect to={l.hash.replace('#', '')} />}
      />
    </Switch>
  )
}

Root.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default enhanced(Root)
