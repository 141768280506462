import React from 'react'
import PropTypes from 'prop-types'

import MessageConfirmation from '../components/MessageConfirmation'
import MessageTokenInvalid from '../components/MessageTokenInvalid'

const Confirmation = ({
  email,
  hasActivated,
  isLoading,
  redirect,
  shouldResetPassword,
  t,
}) =>
  hasActivated ? (
    <MessageConfirmation
      email={email}
      isLoading={isLoading}
      redirect={redirect}
      shouldResetPassword={shouldResetPassword}
      t={t}
    />
  ) : (
    <MessageTokenInvalid t={t} />
  )

Confirmation.propTypes = {
  email: PropTypes.string,
  hasActivated: PropTypes.bool,
  isLoading: PropTypes.bool,
  redirect: PropTypes.func.isRequired,
  shouldResetPassword: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

Confirmation.defaultProps = {
  email: '',
  hasActivated: false,
  isLoading: false,
}

export default Confirmation
