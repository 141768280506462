/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Description, Form, TextInput } from '../../components/Form'
import CustomDropDown from '../../components/Form/CustomDropdown/CustomDropDown'
import formatNumberToBRLCurrency from '../../vendor/helpers/formatNumberToBRLCurrency'
import { isEmail } from '../CreateAccount/validations'
import * as S from './styles'
import { hasLegalAge, isCpf, isDate, isPhone, isRequired } from './validations'

const PartnerData = ({
  occupations,
  onPreviousButton,
  onSubmit,
  registerData,
  t,
}) => {
  const [value, setValue] = useState('')

  const handleChange = event => {
    const formattedValue = formatNumberToBRLCurrency(event.target.value)

    setValue(formattedValue)
  }

  return (
    <Form
      data={registerData}
      onPreviousButton={onPreviousButton}
      onSubmit={onSubmit}
      t={t}
    >
      <S.Row>
        <TextInput
          name="partnerFirstName"
          placeholder={t('pages.partner_data.partner_first_name_placeholder')}
          title={t('pages.partner_data.partner_first_name')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          name="partnerLastName"
          placeholder={t('pages.partner_data.partner_last_name_placeholder')}
          title={t('pages.partner_data.partner_last_name')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          mask="111.111.111-11"
          name="cpf"
          placeholder="000.000.000-00"
          title={t('pages.partner_data.cpf')}
          type="text"
          validations={[isRequired(t), isCpf(t)]}
        />
      </S.Row>
      <S.Row>
        <TextInput
          name="partnerEmail"
          placeholder={t('pages.partner_data.email_placeholder')}
          title={t('pages.partner_data.email')}
          type="email"
          validations={[isRequired(t), isEmail(t)]}
        />
        <TextInput
          mask="11/11/1111"
          name="birthDate"
          placeholder={t('pages.partner_data.birth_date_placeholder')}
          title={t('pages.partner_data.birth_date')}
          type="number"
          validations={[isRequired(t), isDate(t), hasLegalAge(t)]}
        />
        <TextInput
          mask="(11) 11111-1111"
          name="phone"
          placeholder={t('pages.partner_data.phone_placeholder')}
          title={t('pages.partner_data.phone')}
          type="number"
          validations={[isRequired(t), isPhone(t)]}
        />
      </S.Row>
      <S.Row>
        <TextInput
          name="partnerMonthlyBilling"
          onChange={handleChange}
          parse={number => formatNumberToBRLCurrency(number)}
          placeholder={t('pages.partner_data.monthly_billing_placeholder')}
          title={t('pages.partner_data.monthly_billing')}
          type="text"
          validations={[isRequired(t)]}
          value={value}
        />

        <CustomDropDown
          name="position"
          options={occupations}
          placeholder={t('pages.partner_data.position_placeholder')}
          title={t('pages.partner_data.position')}
          validations={[isRequired(t)]}
        />
      </S.Row>
      <TextInput
        name="motherName"
        placeholder={t('pages.partner_data.mother_name_placeholder')}
        title={t('pages.partner_data.mother_name')}
        type="text"
        validations={[isRequired(t)]}
      />
      <Description text={t('pages.partner_data.mother_name_description')} />
    </Form>
  )
}

PartnerData.propTypes = {
  occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPreviousButton: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registerData: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

PartnerData.defaultProps = {
  registerData: {},
}

export default PartnerData
