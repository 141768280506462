import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { isPagarme2Platform } from '../api/company/create/buildParameters'
import WaitingConfirmation from '../containers/WaitingConfirmation'
import { useData } from '../context'
import getPagarmePlatforms from '../vendor/helpers/getPagarmePlatforms'
import { facebookId } from './BankAccountDetails'

const enhanced = translate()

function changeGTMId() {
  const textArea = document.createElement('textarea')
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  textArea.style.visibility = 'hidden'
  document.body.appendChild(textArea)

  window.dataLayer.push({
    clickId: facebookId,
  })

  textArea.click()
}

const WaitingConfirmationPage = ({ t }) => {
  const { data } = useData()
  const { email, webPlatform } = data
  const [platformsVersion2, setPlatformsVersion2] = useState([])

  useEffect(() => {
    ;(async () => {
      const platforms = await getPagarmePlatforms()
      const platformsBusinessVersion2 = platforms.filter(
        platformItem => platformItem.businessVersion === 2,
      )

      setPlatformsVersion2(platformsBusinessVersion2)
    })()
  }, [])

  const { businessVersion } = isPagarme2Platform(webPlatform, platformsVersion2)

  useEffect(() => {
    changeGTMId()
  }, [])

  return (
    <WaitingConfirmation
      businessVersion={businessVersion}
      email={email}
      t={t}
    />
  )
}

WaitingConfirmationPage.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(WaitingConfirmationPage)
