import emailValidation from '../../validation/email'
import requiredValidation from '../../validation/required'

export const equalsString = t => (confirmPassword, { password }) => {
  if (password === confirmPassword) return false
  return t('errors.different_passwords_error')
}
export const isEmail = t => emailValidation(t('validations.isEmail'))

export const isRequired = t => requiredValidation(t('errors.required_error'))
