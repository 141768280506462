import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Text = styled.p`
  color: #4d5062;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 25px;
  margin-top: 0;
`

const Description = ({ text }) => <Text>{text}</Text>

Description.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Description
