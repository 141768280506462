import { always, applySpec, compose, invoker } from 'ramda'

import toNumerical from '../toNumerical'

const formatPhone = applySpec({
  area_code: invoker(2, 'slice')(0, 2),
  country_code: always('55'),
  phone_number: invoker(2, 'slice')(2, Infinity),
})

export default compose(formatPhone, toNumerical)
