import moment from 'moment'
import { evolve, mergeLeft, omit, pipe, replace, toPairs } from 'ramda'
import usePageViewsFactory from './helpers/usePageViewsFactory'

const removeNonDigits = replace(/\D/g, '')

const usePageViews = () => {
  const sendView = () => {
    window.piTracker(window.location.href)
  }

  usePageViewsFactory('piTracker', sendView)
}

const fieldsToOmit = [
  'agency',
  'agencyDv',
  'bankAccount',
  'bankAccountDv',
  'bankAccountType',
  'bankCode',
  'companyTemplateToken',
  'confirmPassword',
  'password',
]

const parseFormData = evolve({
  birthDate: v => moment(v, 'DD/MM/YYYY').format('YYYY-MM-DD'),
  cnpj: removeNonDigits,
  cpf: removeNonDigits,
})

const appendToday = mergeLeft({
  today: moment().format('YYYY-MM-DD'),
})

const makeFormData = pipe(
  omit(fieldsToOmit),
  parseFormData,
  appendToday,
  toPairs,
  entries => new URLSearchParams(entries),
)

const PARDOT_FORM_HANDLER_URL =
  'https://digital.pagar.me/l/1023561/2023-03-29/2xwf'
const sendFormData = data => {
  const formData = makeFormData(data)

  const requestOptions = {
    body: formData,
    method: 'POST',
    mode: 'no-cors',
  }

  return fetch(PARDOT_FORM_HANDLER_URL, requestOptions)
}

export default { sendFormData, usePageViews }
