const factory = dependencies => {
  const { fetch, pagarmeApiUrl } = dependencies

  return token => {
    const body = JSON.stringify({ live: 1, token })
    return fetch(`${pagarmeApiUrl}/companies/activate`, {
      body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
  }
}

export default factory
