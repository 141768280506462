import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as Icon } from '../../images/check.svg'
import * as S from './styles'

const bulletSettings = [
  { name: 'Dados de contato', path: '/boas-vindas-ao-pagarme' },
  { name: 'Sobre a empresa', path: '/dados-da-empresa' },
  { name: 'Representante da empresa', path: '/dados-do-representante' },
  { name: 'Endereço do representante', path: '/endereco-do-representante' },
  { name: 'Endereço da empresa', path: '/endereco-da-empresa' },
  { name: 'Onde receber', path: '/dados-bancarios' },
]

const BulletStepsComponent = ({ history, step }) => (
  <S.Ul>
    {bulletSettings.map(({ name, path }, itemIndex) => {
      const isPreviousItem = step > itemIndex
      const isCurrentItem = step === itemIndex

      return (
        <S.Li key={name}>
          <S.Button
            disabled={!isPreviousItem}
            onClick={() => history.push(path)}
          >
            <S.Bullet
              isCurrentItem={isCurrentItem}
              isPreviousItem={isPreviousItem}
            >
              {isPreviousItem && <Icon />}
            </S.Bullet>
            <S.Span
              isCurrentItem={isCurrentItem}
              isPreviousItem={isPreviousItem}
            >
              {name}
            </S.Span>
          </S.Button>
        </S.Li>
      )
    })}
  </S.Ul>
)

BulletStepsComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  step: PropTypes.number.isRequired,
}

export default BulletStepsComponent
