import { RadioGroup as FormRadioGroup } from 'former-kit'
import PropTypes from 'prop-types'
import { complement, find, isEmpty, juxt, pipe } from 'ramda'
import React from 'react'
import { Field } from 'react-final-form'
import * as S from './styles'

const isNotEmpty = complement(isEmpty)

const RadioGroup = ({ name, onChange, options, title, validations }) => {
  const internalValidate = pipe(juxt(validations), find(Boolean))

  return (
    <Field
      initialValue={options[1].value}
      name={name}
      title={title}
      validate={internalValidate}
    >
      {({ input, meta }) => {
        const isValid = meta.valid && (meta.visited || isNotEmpty(input.value))
        const hasError =
          meta.error && !meta.active && (meta.visited || meta.submitFailed)

        return (
          <S.InputContainer>
            <S.Title data-haserror={hasError} data-isvalid={isValid}>
              {title}
            </S.Title>
            <S.RadioGroupContainer>
              <FormRadioGroup
                data-haserror={hasError}
                data-isvalid={isValid}
                name={input.name}
                onChange={e => {
                  onChange(e.target.value)
                  input.onChange(e.target.value)
                }}
                options={options}
                value={input.value}
              />
            </S.RadioGroupContainer>

            <S.ErrorLabel>{hasError && meta.error}</S.ErrorLabel>
          </S.InputContainer>
        )
      }}
    </Field>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
}

RadioGroup.defaultProps = {
  onChange: () => {},
  validations: [],
}

export default RadioGroup
