/* eslint-disable import/prefer-default-export */
import { sendAmplitudeEvent } from './amplitude'

export const sendErrorEvent = (eventName, error = {}) => {
  const requestErrors = error?.response?.data?.errors
    ?.map(({ message }) => message)
    ?.join('; ')

  sendAmplitudeEvent(eventName, {
    error: {
      ...error,
      response: requestErrors,
    },
  })
}
