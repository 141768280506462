const buildCompanyShouldResetPassword = pagarme1SellerId => ({
  sellerId,
  type,
}) => {
  if (['inbound', 'default'].includes(type)) {
    return true
  }

  return pagarme1SellerId === sellerId
}

export default buildCompanyShouldResetPassword
