import PropTypes from 'prop-types'
import {
  anyPass,
  complement,
  find,
  is,
  isEmpty,
  isNil,
  juxt,
  pipe,
  trim,
  when,
} from 'ramda'
import React from 'react'
import { Field } from 'react-final-form'
import * as S from './styles'

const hasData = complement(anyPass([isNil, isEmpty]))
const formatString = when(is(String), trim)

const TextInput = ({
  disabled,
  inputTooltip,
  mask,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onInputValidChange,
  parse,
  placeholder,
  subTitle,
  title,
  type,
  validations,
  value,
}) => {
  const internalValidate = pipe(juxt(validations), find(Boolean))

  return (
    <Field
      format={formatString}
      formatOnBlur
      name={name}
      parse={parse}
      validate={internalValidate}
    >
      {({ input, meta }) => {
        const isActive = meta.active

        const isValid = meta.valid && (meta.visited || hasData(input.value))
        const hasError = meta.error && (meta.visited || meta.submitFailed)

        const commonProps = {
          ...input,
          dataHasError: hasError && !isActive,
          dataIsValid: isValid && !isActive,
          disabled,
          mask,
          maskChar: null,
          maxLength,
          name,
          onBlur: juxt([onBlur, input.onBlur]),
          onChange: juxt([onChange, input.onChange]),
          onFocus: juxt([onFocus, input.onFocus]),
          placeholder,
          type,
          value: value || input.value,
        }

        onInputValidChange(isValid)

        return (
          <S.InputContainer>
            <S.Title
              data-haserror={hasError && !isActive}
              data-isvalid={isValid && !isActive}
            >
              {title}
              <S.SubTitle>{subTitle}</S.SubTitle>
            </S.Title>
            <S.Input {...commonProps} />
            <S.ErrorLabel>{!isActive && hasError && meta.error}</S.ErrorLabel>
            <S.Tooltip>{inputTooltip}</S.Tooltip>
          </S.InputContainer>
        )
      }}
    </Field>
  )
}

TextInput.propTypes = {
  disabled: PropTypes.bool,
  inputTooltip: PropTypes.node,
  mask: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInputValidChange: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  value: PropTypes.string,
}

TextInput.defaultProps = {
  disabled: false,
  inputTooltip: null,
  mask: undefined,
  maxLength: undefined,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onInputValidChange: () => {},
  parse: undefined,
  placeholder: undefined,
  subTitle: '',
  validations: [],
  value: '',
}

export default TextInput
