import React from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import logo from '../../images/full-logo.svg'
import minifiedLogo from '../../images/mini-logo.svg'

const Image = styled.img`
  margin-bottom: 32px;
  margin-top: 10px;

  ${props =>
    !props.isFirstPage &&
    css`
      margin-bottom: 0px;
    `}

  ${media.greaterThan('large')`
    margin-bottom: 100px;
  `}
`

const Logo = ({ isFirstPage }) => (
  <a href="https://pagar.me">
    <Media
      queries={{
        mobile: '(max-width: 1160px)',
      }}
    >
      {({ mobile }) =>
        mobile && !isFirstPage ? (
          <Image alt="Pagar.me" src={minifiedLogo} />
        ) : (
          <Image alt="Pagar.me" isFirstPage src={logo} />
        )
      }
    </Media>
  </a>
)

Logo.propTypes = {
  isFirstPage: PropTypes.bool.isRequired,
}

export default Logo
