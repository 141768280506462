import styled from 'styled-components'
import media from 'styled-media-query'

export const FirstRow = styled.div`
  ${media.greaterThan('large')`
    display: flex;
    justify-content: space-between;
    
    & > div {
        width: 48%;
    }
  `}
`

export const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.greaterThan('large')`
    & > div:nth-child(odd) {
        width: 32%;
    }

    & > div:nth-child(even) {
        width: 12%;
    }
  `}

  ${media.lessThan('large')`
    flex-wrap: wrap;

    & > div:nth-child(odd) {
        width: 70%;
    }

    & > div:nth-child(even) {
        width: 24%;
    }
  `}
`

export const Text = styled.p`
  color: #4d5062;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 25px;
  margin-top: 0;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${media.greaterThan('medium')`
    margin-top: 120px;
  `}
`
