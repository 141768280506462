import React from 'react'
import FullStory from 'react-fullstory'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter } from 'react-router-dom'
import { DataProvider } from './context'
import { googleMarketingPlatform, recaptchaKey } from './environment'
import Root from './pages/Root'
import ReactGTag from './vendor/reactGTag'
import { Datadog } from './components/Datadog'

const productionUrl = window.location.host.startsWith('cadastro.pagar.me')

const App = () => (
  <>
    <ReactGTag.init
      analyticsId={googleMarketingPlatform.analyticsId}
      optimizeId={googleMarketingPlatform.optimizeId}
    />
    <Datadog />
    {productionUrl && <FullStory org="BKP05" />}
    <BrowserRouter>
      <DataProvider>
        <GoogleReCaptchaProvider
          container={{
            element: 'recaptcha-v3',
            parameters: {
              badge: 'bottomleft',
            },
          }}
          reCaptchaKey={recaptchaKey}
        >
          <Root />
        </GoogleReCaptchaProvider>
      </DataProvider>
    </BrowserRouter>
  </>
)

export default App
