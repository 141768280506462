const offersPageVariants = [
  'MDRzão sem preço', // original
  'MDRzão com preço', // variant 1
]

const getExperimentVariant = experimentId => {
  const variantId =
    window.google_optimize && window.google_optimize.get(experimentId)
  return offersPageVariants[variantId]
}

export default getExperimentVariant
