/* eslint-disable import/prefer-default-export */
import axios from 'axios'

export const getCep = async cep => {
  if (!cep || cep.includes('_') || cep.length < 9) {
    return
  }

  try {
    const parsedCep = cep.replace('-', '')
    const response = await axios.get(
      `https://brasilapi.com.br/api/cep/v1/${parsedCep}`,
    )

    // eslint-disable-next-line consistent-return
    return response.data
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}
