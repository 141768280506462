import axios from 'axios'
import { apiUrl } from '../../../environment'
import headers from '../../headers'
import { formatData } from './buildParameters'

const createCompany = async (
  companyData,
  visitorId,
  requestid,
  platformsBusinessVersion2,
) => {
  const dataToSend = formatData(companyData, platformsBusinessVersion2)
  delete dataToSend.Facebook_Lead_ID

  const body = JSON.stringify(dataToSend)

  return axios.post(`${apiUrl}/customers`, body, {
    headers: {
      ...headers,
      'g-recaptcha-response': companyData.recaptchaToken,
      requestid,
      visitorId,
    },
  })
}

export default createCompany
