import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const RotateKeyframe = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerContainer = styled.div(
  props => css`
    align-self: center;
    height: ${props.height || '135px'};
    justify-self: center;
    width: ${props.width || '135px'};

    & svg > circle {
      transform-box: fill-box;
      transform-origin: center;
    }

    & svg > circle:first-of-type {
      animation: ${RotateKeyframe} 1.5s linear infinite;
    }

    & svg > circle:last-of-type {
      animation: ${RotateKeyframe} 0.8s linear infinite;
    }
  `,
)

const Spinner = props => (
  <SpinnerContainer {...props}>
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="50"
        cy="50"
        fill="none"
        r="46.5"
        stroke="#693FCF"
        strokeDasharray="150 30"
        strokeLinecap="round"
        strokeWidth="5.5"
      />
      <circle
        cx="50"
        cy="50"
        fill="none"
        r="35"
        stroke="#a8c038"
        strokeDasharray="50 200"
        strokeLinecap="round"
        strokeWidth="5.5"
      />
    </svg>
  </SpinnerContainer>
)

export default Spinner
