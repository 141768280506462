import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import HorizontalCurves from '../../images/curves-horizontal.svg'
import Curves from '../../images/curves-vertical.svg'
import DonutImageBackground from '../../images/donut.svg'
import DotsGreenImage from '../../images/dots-green.svg'

export const SectionWrapper = styled.section`
  ${media.greaterThan('large')`
    background: linear-gradient(to right, #ffffff 50%, #f9f9f9 50%);
  `}
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1400px;
  min-height: 100vh;

  ${media.greaterThan('large')`
    flex-direction: row;
    justify-content: center;
  `}
`

export const AsideWrapper = styled.aside(
  props => css`
    flex-shrink: 0;
    overflow-wrap: break-word;
    padding: 16px 24px;
    width: 100%;

    ${media.lessThan('large')`
      ${!props.isFirstPage &&
        css`
          & > div {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        `}
    `}

    ${media.greaterThan('large')`
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 310px;
      padding: 0;
      padding-left: 40px;

      & > div {
        height: 690px;
      }
    `}
  `,
)

export const TitleText = styled.h2`
  color: #4d5062;
  font-size: 24px;
  line-height: 1.5;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-bottom: 16px;

  & > span {
    color: #a8c038;
  }
`

export const Text = styled.p`
  color: #4d5062;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 24px;
`

export const BulletWrapper = styled.div(
  props => css`
    color: #9092a2;
    margin-bottom: 5px;
    margin-top: 30px;

    ${!props.isFirstPage &&
      css`
        margin: 0;
      `}

    ${media.greaterThan('large')`
    margin-top: 50px;
  `}
  `,
)

export const FormWrapper = styled.section`
  align-items: center;
  background-color: #f9f9f9;
  background-image: url(${HorizontalCurves}), url(${DonutImageBackground}),
    url(${DotsGreenImage});
  background-position: 0 -2px, calc(100% + 50px) -50px, left calc(100% - -5px);
  background-repeat: no-repeat;
  background-size: 100% auto, 130px 130px, 170px 46px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 0px 32px 35px 32px;

  & > main {
    width: 100%;
  }

  ${media.greaterThan('large')`
    align-items: flex-start;
    background-image: url(${Curves});
    background-size: contain;
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
    padding-left: 13rem;

    & > main {
      height: 690px;
      width: 80%;
    }
  `}

  ${media.greaterThan('huge')`
    padding-left: 16rem;
  `}
`

export const SectionName = styled.h1`
  align-self: start;
  color: #4d5062;
  font-size: 24px;
  font-weight: bold;
  line-height: 59px;
  margin-bottom: 10px;

  ${media.greaterThan('medium')`
    margin-top: 50px;
  `}

  ${media.greaterThan('large')`
    font-size: 40px;
    margin-top: 0px;
  `}

  ${media.lessThan('small')`
    line-height: 36px;
  `}
`

export const SectionSubtitle = styled.p`
  color: #595a63;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  margin-top: 0;

  ${media.greaterThan('large')`
    margin-bottom: 26px;
  `}
`

export const DonutImage = styled.img`
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);

  ${media.greaterThan('large')`
    display: block;
  `}
`

export const TextWrapper = styled.div`
  display: none;

  ${props =>
    props.isFirstPage &&
    css`
      display: block;
    `}

  ${media.greaterThan('large')`
    display: block;
  `}
`
