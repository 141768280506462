import styled from 'styled-components'
import media from 'styled-media-query'

export const ConfirmActions = styled.div`
  display: flex;

  ${media.lessThan('large')`
    flex-direction: column-reverse;
    padding-top: 20px;
    margin-bottom: 50px;
  `}
`

export const ReturnButton = styled.button`
  appearance: none;
  background-color: #f9f9f9;
  border: none;
  color: #6045af;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  outline: none;
  padding-left: 32px;
  padding-right: 48px;
  transition: background 0.3s;

  ${media.lessThan('medium')`
    padding-left: 0px;
    padding-right: 3px;
  `}
`

export const ContinueButton = styled.button`
  background-color: ${props => (props.disabled ? '#9092A2' : '#6045AF')};
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  margin-left: 5px;
  padding-left: 32px;
  padding-right: 32px;
  text-transform: uppercase;
  transition: background 0.3s;
  width: 100%;

  ${props =>
    !props.disabled &&
    `
    cursor: pointer;

    &:hover {
      background-color: #3F2787;
    }
  `}

  ${media.lessThan('medium')`
    margin-left: 10px;
    padding-left: 0px;
    padding-right: 0px;
  `}
`
