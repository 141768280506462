import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'
import Spinner from '../Spinner'

import { ReactComponent as Dots } from './dots.svg'
import { ReactComponent as Donut } from './donut.svg'
import { ReactComponent as Worms } from './waves.svg'

const MessageContainer = ({ action, image, isLoading, message, title }) => (
  <div className={styles.messageWrapper}>
    <div className={styles.rowStart}>
      <Dots className={styles.dots} />
      <Worms className={styles.worms} />
    </div>

    {isLoading ? (
      <Spinner />
    ) : (
      <div className={styles.messageContainer}>
        <div>
          <h2 className={styles.messageTitle}>{title}</h2>
          <div className={styles.messageText}>{message}</div>

          {action && (
            <button
              className={styles.messageLink}
              onClick={action.value}
              type="button"
            >
              {action.text}
            </button>
          )}
        </div>

        <div>{image}</div>
      </div>
    )}

    <div className={styles.alignEnd}>
      <Donut className={styles.donut} />
    </div>
  </div>
)

MessageContainer.propTypes = {
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.func.isRequired,
  }),
  image: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  message: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}

MessageContainer.defaultProps = {
  action: null,
  isLoading: false,
}

export default MessageContainer
