import React from 'react'
import PropTypes from 'prop-types'

const withRedirect = WrappedComponent => {
  const WithRedirect = ({ history, location, ...rest }) => {
    const redirect = url => {
      if (!url) return

      const { search } = location

      const queryParamsSeparator = url?.includes('?') ? '&' : '?'
      const queryParams = search
        ? `${queryParamsSeparator}${search.replaceAll('?', '')}`
        : ''

      history.push(`${url}${queryParams}`)
    }

    const props = {
      history,
      location,
      redirect,
      ...rest,
    }

    return <WrappedComponent {...props} />
  }

  WithRedirect.displayName = `withRedirect(${WrappedComponent.displayName ||
    WrappedComponent.name})`

  WithRedirect.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
  }

  return WithRedirect
}

export default withRedirect
