import React from 'react'
import PropTypes from 'prop-types'
import Message from './MessageContainer'
import { ReactComponent as Confirmation } from '../images/confirmation.svg'

const MessageTokenInvalid = ({ t }) => (
  <Message
    image={<Confirmation />}
    message={<p>{t('pages.confirmation.has_company_activated_description')}</p>}
    title={t('pages.confirmation.has_company_activated')}
  />
)

MessageTokenInvalid.propTypes = {
  t: PropTypes.func.isRequired,
}

export default MessageTokenInvalid
