import cnpjValidation from '../../validation/cnpj'
import httpsValidation from '../../validation/https'
import phoneValidation from '../../validation/phoneNumber'
import requiredValidation from '../../validation/required'
import urlValidation from '../../validation/url'

export const isRequired = t => requiredValidation(t('errors.required_error'))
export const isCnpj = t => cnpjValidation(t('validations.isCnpj'))
export const isUrl = t => urlValidation(t('validations.isUrl'))
export const isHttps = t => httpsValidation(t('validations.isHttps'))
export const isPhone = t => phoneValidation(t('validations.isPhone'))
