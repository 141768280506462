const parseCurrencyStringToInteger = currencyString => {
  let numericString = currencyString.replace(/[^\d,.]/g, '')

  numericString = numericString.replace(/[.]/g, '').replace(/,/g, '.')

  const parsedAmount = parseFloat(numericString)

  return Math.round(parsedAmount * 100)
}

export default parseCurrencyStringToInteger
