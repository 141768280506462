import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from '../components/Form'
import CompanyAddress from '../containers/CompanyAddress'
import { useData } from '../context'
import { getCep } from '../vendor/helpers/getCep'

const CompanyAddressPage = ({ history, redirect, t }) => {
  const [disableInput, setDisableInput] = useState(false)
  const { data, setData } = useData()

  const handleCepRequest = async (cep, form) => {
    setDisableInput(true)
    const cepData = await getCep(cep)

    form.change('companyCep', cep)
    form.change('companyCity', cepData.city)
    form.change('companyNeighborhood', cepData.neighborhood)
    form.change('companyState', cepData.state)
    form.change('companyStreet', cepData.street)

    setDisableInput(false)
  }

  const handleSubmit = newRegisterData => {
    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/dados-bancarios')
  }

  return (
    <Form
      data={data}
      onPreviousButton={() => history.goBack()}
      onSubmit={handleSubmit}
      t={t}
    >
      <CompanyAddress
        disableInput={disableInput}
        getCep={handleCepRequest}
        t={t}
      />
    </Form>
  )
}

CompanyAddressPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CompanyAddressPage
