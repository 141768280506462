/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

export const Datadog = () => {
  const environment =
    process.env.REACT_APP_APPLICATION_ENVIRONMENT || 'development'
  const projectName = 'pagarme/big-bang'
  const owner = 'stone-payments/payments-experience-acquisition'

  useEffect(() => {
    const enabled =
      process.env.REACT_APP_DATADOG_ENABLED === 'true' ||
      environment !== 'development'

    if (!enabled) return

    const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID
    if (!applicationId) throw new Error('Datadog Application ID not found')

    const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
    if (!clientToken) throw new Error('Datadog Client Token not found')

    const costCenter = process.env.REACT_APP_DATADOG_COST_CENTER
    if (!costCenter) throw new Error('Datadog Cost Center not found')

    datadogRum.init({
      applicationId,
      clientToken,
      costCenter,
      defaultPrivacyLevel: 'mask-user-input',
      env: environment,
      owner,
      project: projectName,
      service: projectName,
      sessionReplaySampleRate: 10,
      sessionSampleRate: 100,
      site: 'datadoghq.com',
      support: owner,
      trackUserInteractions: true,
      version: '1.0.0',
    })
  }, [environment])

  return null
}
