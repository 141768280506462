import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Form } from '../components/Form'
import PartnerAddress from '../containers/PartnerAddress'
import { useData } from '../context'
import { getCep } from '../vendor/helpers/getCep'

const PartnerAddressPage = ({ history, redirect, t }) => {
  const { data, setData } = useData()
  const [disableInput, setDisableInput] = useState(false)

  const handleCepRequest = async (cep, form) => {
    setDisableInput(true)
    const cepData = await getCep(cep)

    form.change('partnerCep', cep)
    form.change('partnerCity', cepData.city)
    form.change('partnerNeighborhood', cepData.neighborhood)
    form.change('partnerState', cepData.state)
    form.change('partnerStreet', cepData.street)

    setDisableInput(false)
  }

  const handleSubmit = newRegisterData => {
    window.dataLayer.push({
      city: newRegisterData.partnerCity,
      state: newRegisterData.partnerState,
      zipcode: newRegisterData.partnerCep,
    })

    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/endereco-da-empresa')
  }

  return (
    <Form
      data={data}
      onPreviousButton={() => history.goBack()}
      onSubmit={handleSubmit}
      t={t}
    >
      <PartnerAddress
        disableInput={disableInput}
        getCep={handleCepRequest}
        t={t}
      />
    </Form>
  )
}

PartnerAddressPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default PartnerAddressPage
