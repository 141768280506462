import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CompanyData from '../containers/CompanyData'
import { useData } from '../context'
import getPagarmePlatforms from '../vendor/helpers/getPagarmePlatforms'

const CompanyDetailsPage = ({ history, redirect, t }) => {
  const { data, setData } = useData()
  const [platforms, setPlatforms] = useState([])

  const handleSubmit = newRegisterData => {
    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/dados-do-representante')
  }

  useEffect(() => {
    ;(async () => {
      const filteredActivePlataforms = await getPagarmePlatforms()

      setPlatforms(filteredActivePlataforms)
    })()
  }, [setPlatforms])

  return (
    <CompanyData
      onPreviousButton={() => history.goBack()}
      onSubmit={handleSubmit}
      platforms={platforms}
      registerData={data}
      t={t}
    />
  )
}

CompanyDetailsPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CompanyDetailsPage
