import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-final-form'
import { DropDown, RadioGroup, TextInput } from '../../components/Form'
import optionsStates from '../../models/states.json'
import * as S from './styles'
import { isCep, isRequired } from './validations'

const CompanyAddress = ({ disableInput, getCep, t }) => {
  const form = useForm()

  const { values } = form.getState()

  const handleSameAddress = option => {
    if (option === 'Sim') {
      form.change('companyCep', values.partnerCep)
      form.change('companyCity', values.partnerCity)
      form.change('companyNeighborhood', values.partnerNeighborhood)
      form.change('companyState', values.partnerState)
      form.change('companyStreet', values.partnerStreet)
      form.change('companyNumber', values.partnerNumber)
      form.change('companyComplement', values.partnerComplement)
      form.change('companyReferencePoint', values.partnerReferencePoint)
    } else {
      form.change('companyCep', '')
      form.change('companyCity', '')
      form.change('companyNeighborhood', '')
      form.change('companyState', '')
      form.change('companyStreet', '')
      form.change('companyNumber', '')
      form.change('companyComplement', '')
      form.change('companyReferencePoint', '')
    }
  }

  return (
    <>
      <RadioGroup
        name="isSameAddress"
        onChange={handleSameAddress}
        options={[
          {
            name: 'Sim',
            value: 'Sim',
          },
          {
            name: 'Não',
            value: 'Não',
          },
        ]}
        title="O endereço da empresa é o mesmo do representante?"
        validations={[isRequired(t)]}
      />
      <S.Container>
        <TextInput
          mask="11111-111"
          name="companyCep"
          onChange={event => getCep(event.target.value, form)}
          placeholder={t('pages.company_address.cep_placeholder')}
          title={t('pages.company_address.cep')}
          type="text"
          validations={[isRequired(t), isCep(t)]}
        />
        <TextInput
          disabled={disableInput}
          name="companyStreet"
          placeholder={t('pages.company_address.street_placeholder')}
          title={t('pages.company_address.street')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.Container>

      <S.Container>
        <TextInput
          name="companyNumber"
          placeholder={t('pages.company_address.number_placeholder')}
          title={t('pages.company_address.number')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          name="companyComplement"
          placeholder={t('pages.company_address.complement_placeholder')}
          title={t('pages.company_address.complement')}
          type="text"
        />
        <TextInput
          name="companyReferencePoint"
          placeholder={t('pages.company_address.reference_point_placeholder')}
          title={t('pages.company_address.reference_point')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.Container>

      <S.Container>
        <TextInput
          disabled={disableInput}
          name="companyNeighborhood"
          placeholder={t('pages.company_address.neighborhood_placeholder')}
          title={t('pages.company_address.neighborhood')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          disabled={disableInput}
          name="companyCity"
          placeholder={t('pages.company_address.city_placeholder')}
          title={t('pages.company_address.city')}
          type="text"
          validations={[isRequired(t)]}
        />
        <DropDown
          disabled={disableInput}
          name="companyState"
          options={optionsStates}
          placeholder={t('pages.company_address.state_placeholder')}
          title={t('pages.company_address.state')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.Container>
    </>
  )
}

CompanyAddress.propTypes = {
  disableInput: PropTypes.bool.isRequired,
  getCep: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CompanyAddress
