import styled, { css } from 'styled-components'
import Select from 'react-select'
import { FormInput } from 'former-kit'
import { always, cond, curry, prop, T } from 'ramda'

const loadColor = curry(defaultColor =>
  cond([
    [prop('data-haserror'), always('#cf3f3f')],
    [prop('data-isvalid'), always('#a8c038')],
    [T, always(defaultColor)],
  ]),
)

export const ErrorLabel = styled.label`
  color: #cf3f3f;
  font-size: 12px;
  margin-top: 10px;
  min-width: 60px;
`

export const Input = styled(FormInput)`
  & > div > div > input {
    background-color: #f9f9f9;
    border: 1px solid ${loadColor('#4d5062')};
    border-radius: 6px;
    box-sizing: border-box;
    height: 54px;
    padding: 16px;
    width: 100%;

    &:focus {
      border: 1px solid ${loadColor('#363845')};
      color: #363845;
    }
  }

  & > div > div > button {
    appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 25px 22px;
    border: none;
    cursor: pointer;
    height: 24px;
    margin-top: 17px;
    outline: none;
    position: absolute;
    right: 32px;
    width: 27px;
  }
`

export const DropDown = styled(Select)`
  font-size: 14px;
  height: 54px;

  &:disabled {
    border-color: #f9f9f9;
  }

  & > div:first-of-type {
    background-color: #f9f9f9;
    height: 100%;
  }

  & > div {
    background-color: #f9f9f9;
    border: 1px solid ${loadColor('#363845')} !important;
    color: #363845;
    margin-top: 1px;

    ${props =>
      props.isDisabled &&
      css`
        border: 1px solid #a8a8ad !important;
        color: #a8a8ad;
      `}
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 24px;
  min-width: 50px;
  position: relative;
  width: 100%;

  ${props =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
    `}
`

export const Title = styled.label`
  color: ${loadColor('#4d5062')};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  width: 100%;

  ${props =>
    props.isDisabled &&
    css`
      color: #a8a8ad;
    `}
`

export const Tooltip = styled.div`
  margin-top: 55px;
  position: absolute;
  right: 15px;
`

export const GroupStyles = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const GroupBadgeStyles = styled.span`
  background: #a8a8ad;
  border-radius: 1em;
  display: inline-block;
  height: 2px;
  margin: -8px auto 10px auto;
  min-width: 1;
  width: 100%;
`
