import React from 'react'
import PropTypes from 'prop-types'
import MessageContainer from '../components/MessageContainer'
import { ReactComponent as VerifyEmail } from '../images/verify-email.svg'

const WaitingConfirmation = ({ businessVersion, email, t }) => (
  <MessageContainer
    image={<VerifyEmail />}
    message={
      businessVersion === 'pagarme1' ? (
        <>
          <p>
            {t('pages.waiting_confirmation.pagarme1.message_email_sent')}{' '}
            <span>
              {t(
                'pages.waiting_confirmation.pagarme1.message_email_sent_enphasis',
              )}
            </span>
          </p>

          <p>{t('pages.waiting_confirmation.pagarme1.message_support')}</p>
        </>
      ) : (
        <>
          <p>
            <strong>
              {t('pages.waiting_confirmation.pagarme2.message_email_sent')}{' '}
              <span>{email}</span>
            </strong>
          </p>

          <p>{t('pages.waiting_confirmation.pagarme2.message_inbox')}</p>
          <p>{t('pages.waiting_confirmation.pagarme2.message_support')}</p>
        </>
      )
    }
    title={
      businessVersion === 'pagarme1' ? (
        <>
          {t('pages.waiting_confirmation.pagarme1.title')}
          <br />
          <span>{email}</span>
        </>
      ) : (
        <>
          {t('pages.waiting_confirmation.pagarme2.title')},{' '}
          <span>{t('pages.waiting_confirmation.pagarme2.title_enphasis')}</span>
        </>
      )
    }
  />
)

WaitingConfirmation.propTypes = {
  businessVersion: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default WaitingConfirmation
