import { useEffect } from 'react'
import { chatMiaw } from '../../environment'

const createInitScript = (scriptId, id, team, src, scrt) => {
  const initScript = document.createElement('script')
  initScript.id = scriptId
  initScript.type = 'text/javascript'
  initScript.innerHTML = `
    function initEmbeddedMessaging() {
      try {
        embeddedservice_bootstrap.settings.language = 'pt_BR';
        embeddedservice_bootstrap.init('${id}', '${team}', '${src}',
          {scrt2URL: '${scrt}'}
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    };
  `
  document.head.appendChild(initScript)
}

const createLoadScript = (id, src) => {
  const loadScript = document.createElement('script')
  loadScript.id = id
  loadScript.type = 'text/javascript'
  loadScript.src = `${src}/assets/js/bootstrap.min.js`
  loadScript.onload = () => {
    /* eslint-disable no-undef */
    if (typeof initEmbeddedMessaging === 'function') {
      initEmbeddedMessaging()
    } else {
      /* eslint-disable no-console */
      console.error('initEmbeddedMessaging function is not defined.')
    }
  }
  document.head.appendChild(loadScript)
}

const Chat = () => {
  useEffect(() => {
    const initScriptId = 'embeddedMessagingInitScript'
    const loadScriptId = 'embeddedMessagingLoadScript'
    const { id, scrt, src, team } = chatMiaw

    if (!document.getElementById(initScriptId) && id && team && src && scrt) {
      createInitScript(initScriptId, id, team, src, scrt)
    }

    if (!document.getElementById(loadScriptId) && src) {
      createLoadScript(loadScriptId, src)
    }
  }, [])

  return null
}

export default Chat
