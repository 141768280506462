import {
  both,
  complement,
  isNil,
  pathSatisfies,
  propSatisfies,
  startsWith,
  uncurryN,
} from 'ramda'

const isNotNil = complement(isNil)

const hasTrackerTag = trackerTag => propSatisfies(isNotNil, trackerTag)
const isProduction = pathSatisfies(startsWith('cadastro.pagar.me'), [
  'location',
  'host',
])
const shouldSendEvent = uncurryN(2, trackerTag =>
  both(hasTrackerTag(trackerTag), isProduction),
)

export default shouldSendEvent
