import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const P = styled.p`
  color: #595a63;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 20px;
  margin-top: -10px;

  ${media.lessThan('large')`
    margin-bottom: 10px;
    margin-top: 0px;
  `}
`

const A = styled.a`
  color: #65a300;
  font-weight: bold;
  text-decoration: none;
`

const TermsOfUse = ({ t }) => {
  return (
    <P>
      {t('pages.create_account.terms_of_use')}
      <A
        href="https://pagar.me/documentos/termos-de-uso.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        {`${t('pages.create_account.terms_of_use_link_terms')}, `}
      </A>
      <A
        href="https://pagar.me/aviso-de-cookies"
        rel="noopener noreferrer"
        target="_blank"
      >
        {`${t('pages.create_account.terms_of_use_link_cookies')} `}
      </A>
      {`${t('pages.create_account.terms_of_use_link_intersection')} `}
      <A
        href="https://pagar.me/aviso-de-privacidade"
        rel="noopener noreferrer"
        target="_blank"
      >
        {`${t('pages.create_account.terms_of_use_link_privacy')} `}
      </A>
      {t('pages.create_account.terms_of_use_pagarme')}
    </P>
  )
}

TermsOfUse.propTypes = {
  t: PropTypes.func.isRequired,
}

export default TermsOfUse
