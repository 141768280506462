import styled from 'styled-components'
import media from 'styled-media-query'

// eslint-disable-next-line import/prefer-default-export
export const Row = styled.div`
  display: flex;
  gap: 0 1.25rem;
  justify-content: space-between;

  &:nth-of-type(1) {
    flex: 1;
  }

  & > div {
    width: calc(50% - 16px);

    label {
      white-space: nowrap;
    }
  }

  ${media.lessThan('large')`
      flex-direction: column;

      & > div {
        width: 100%;
      }
  `}
`
