/* eslint-disable no-restricted-globals */
const formatNumberToBRLCurrency = value => {
  let formattedValue = ''

  const numericValue = parseFloat(value.replace(/[^\d]/g, ''))

  if (!isNaN(numericValue)) {
    formattedValue = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format(numericValue / 100)
  }

  return formattedValue
}

export default formatNumberToBRLCurrency
