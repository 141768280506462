import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import shouldSendEvent from './shouldSendEvent'

const usePageViewsFactory = (trackerTag, sendView) => {
  const location = useLocation()

  useEffect(() => {
    if (shouldSendEvent(trackerTag, window)) {
      sendView(location)
    }
  }, [location, sendView, trackerTag])
}

export default usePageViewsFactory
