import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { complement, isEmpty, find, juxt, pipe } from 'ramda'
import * as S from './styles'

const isNotEmpty = complement(isEmpty)

const setReactSelectStyles = {
  control: base => ({
    ...base,
    '&:hover': {
      border: '0',
    },
    border: '0',
    boxShadow: '0',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#A8C038' : '#f9f9f9',
    cursor: 'pointer',
  }),
}

const FormDropDown = ({
  disabled,
  name,
  options,
  placeholder,
  title,
  validations,
}) => {
  const internalValidate = pipe(juxt(validations), find(Boolean))

  return (
    <Field
      name={name}
      placeholder={placeholder}
      title={title}
      validate={internalValidate}
    >
      {({ input, meta }) => {
        const value = options.find(option => option.value === input.value)

        const isValid = meta.valid && (meta.visited || isNotEmpty(input.value))
        const hasError =
          meta.error && !meta.active && (meta.visited || meta.submitFailed)

        return (
          <S.InputContainer>
            <S.Title data-haserror={hasError} data-isvalid={isValid}>
              {title}
            </S.Title>
            <S.DropDown
              data-haserror={hasError}
              data-isvalid={isValid}
              isDisabled={disabled}
              name={input.name}
              noOptionsMessage={() => <div>Nenhum resultado</div>}
              onBlur={input.onBlur}
              onChange={option => {
                input.onChange(option.value)
              }}
              options={options}
              placeholder={placeholder}
              styles={setReactSelectStyles}
              value={value}
            />
            <S.ErrorLabel>{hasError && meta.error}</S.ErrorLabel>
          </S.InputContainer>
        )
      }}
    </Field>
  )
}

FormDropDown.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
}

FormDropDown.defaultProps = {
  disabled: false,
  validations: [],
}

export default FormDropDown
