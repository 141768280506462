import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-final-form'
import { DropDown, TextInput } from '../../components/Form'
import * as S from './styles'
import { isCep, isRequired } from './validations'

import optionsStates from '../../models/states.json'

const PartnerAddress = ({ disableInput, getCep, t }) => {
  const form = useForm()

  return (
    <>
      <S.Container>
        <TextInput
          mask="11111-111"
          name="partnerCep"
          onChange={event => getCep(event.target.value, form)}
          placeholder={t('pages.partner_address.cep_placeholder')}
          title={t('pages.partner_address.cep')}
          type="text"
          validations={[isRequired(t), isCep(t)]}
        />
        <TextInput
          disabled={disableInput}
          name="partnerStreet"
          placeholder={t('pages.partner_address.street_placeholder')}
          title={t('pages.partner_address.street')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.Container>

      <S.Container>
        <TextInput
          name="partnerNumber"
          placeholder={t('pages.partner_address.number_placeholder')}
          title={t('pages.partner_address.number')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          name="partnerComplement"
          placeholder={t('pages.partner_address.complement_placeholder')}
          title={t('pages.partner_address.complement')}
          type="text"
        />
        <TextInput
          name="partnerReferencePoint"
          placeholder={t('pages.partner_address.reference_point_placeholder')}
          title={t('pages.partner_address.reference_point')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.Container>

      <S.Container>
        <TextInput
          disabled={disableInput}
          name="partnerNeighborhood"
          placeholder={t('pages.partner_address.neighborhood_placeholder')}
          title={t('pages.partner_address.neighborhood')}
          type="text"
          validations={[isRequired(t)]}
        />
        <TextInput
          disabled={disableInput}
          name="partnerCity"
          placeholder={t('pages.partner_address.city_placeholder')}
          title={t('pages.partner_address.city')}
          type="text"
          validations={[isRequired(t)]}
        />
        <DropDown
          disabled={disableInput}
          name="partnerState"
          options={optionsStates}
          placeholder={t('pages.partner_address.state_placeholder')}
          title={t('pages.partner_address.state')}
          type="text"
          validations={[isRequired(t)]}
        />
      </S.Container>
    </>
  )
}

PartnerAddress.propTypes = {
  disableInput: PropTypes.bool.isRequired,
  getCep: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default PartnerAddress
