import React from 'react'
import PropTypes from 'prop-types'
import MessageContainer from './MessageContainer'
import { ReactComponent as Confirmation } from '../images/confirmation.svg'

const MessageConfirmation = ({
  email,
  isLoading,
  redirect,
  shouldResetPassword,
  t,
}) => (
  <MessageContainer
    action={redirect}
    image={<Confirmation />}
    isLoading={isLoading}
    message={
      <>
        <p>
          {t('pages.confirmation.successful_message_1')}
          <b>{email}</b>
          {t('pages.confirmation.successful_message_2')}
        </p>

        <p>
          {shouldResetPassword
            ? t('pages.confirmation.successful_message_reset_password')
            : t('pages.confirmation.successful_message_guide')}
        </p>
      </>
    }
    title={<>{t('pages.confirmation.successful')} </>}
  />
)

MessageConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  redirect: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.func,
  }).isRequired,
  shouldResetPassword: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

MessageConfirmation.defaultProps = {
  isLoading: false,
  shouldResetPassword: false,
}

export default MessageConfirmation
