/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import media from 'styled-media-query'

export const Row = styled.div`
  display: flex;
  gap: 0 1.25rem;

  & > div {
    flex: 1;
  }

  ${media.lessThan('large')`
      flex-direction: column;

      & > div:first-child {
          width: 100%;
      }
    `}
`
