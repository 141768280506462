import PropTypes from 'prop-types'
import { equals, join, pipe, split, take } from 'ramda'
import React, { useEffect } from 'react'
import Chat from '../Chat'
import * as S from './styles'

import Donut from '../../images/donut.svg'
import BulletStepsComponent from '../BulletSteps'
import Logo from '../Logo'

/* eslint-disable sort-keys */
const settingsByPath = {
  '/boas-vindas-ao-pagarme': { step: 0, translatePage: 'create_account' },
  '/dados-da-empresa': { step: 1, translatePage: 'company_data' },
  '/dados-do-representante': { step: 2, translatePage: 'partner_data' },
  '/endereco-do-representante': { step: 3, translatePage: 'partner_address' },
  '/endereco-da-empresa': { step: 4, translatePage: 'company_address' },
  '/dados-bancarios': { step: 5, translatePage: 'bank_account' },
  '/confirmar-dados': { step: 6, translatePage: 'confirm_data' },
}

const cleanPathName = pipe(split('/'), take(2), join('/'))
const equalsZero = equals(0)

const FormBackground = ({ children, history, path, t }) => {
  const { step, translatePage } = settingsByPath[cleanPathName(path)]
  const isFirstPage = equalsZero(step)

  useEffect(() => window.scroll({ behavior: 'smooth', top: 0 }))

  return (
    <S.SectionWrapper>
      <S.SectionContainer>
        <S.AsideWrapper isFirstPage={isFirstPage}>
          <div>
            <Logo isFirstPage={isFirstPage} />

            <S.TextWrapper isFirstPage={isFirstPage}>
              <S.TitleText>
                Comece a <span>vender hoje</span>
                <br />
                com o Pagar.me!
              </S.TitleText>

              <S.Text>
                Para que você tenha acesso às nossas
                <br />
                facilidades, gostaríamos de te
                <br />
                conhecer melhor -
                <br />
                e também o seu negócio.
                <br />
                Cadastre-se, leva poucos minutos!
              </S.Text>
            </S.TextWrapper>

            <S.BulletWrapper isFirstPage={isFirstPage}>
              <BulletStepsComponent history={history} step={step} />
            </S.BulletWrapper>
          </div>
        </S.AsideWrapper>
        <S.FormWrapper>
          <S.DonutImage alt="" src={Donut} />
          <main>
            <S.SectionName>
              {t(`pages.${translatePage}.form_title`)}
            </S.SectionName>
            <S.SectionSubtitle>
              {t(`pages.${translatePage}.form_subtitle`)}
            </S.SectionSubtitle>
            {children}
          </main>
        </S.FormWrapper>
      </S.SectionContainer>

      <Chat />
    </S.SectionWrapper>
  )
}

FormBackground.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default FormBackground
