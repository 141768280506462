import styled from 'styled-components'
import Select from 'react-select'
import { FormInput } from 'former-kit'
import { always, cond, curry, prop, T } from 'ramda'

import visibilityOn from '../../images/visibility.svg'
import visibilityOff from '../../images/visibility-off.svg'

const loadColor = curry(defaultColor =>
  cond([
    [prop('data-haserror'), always('#cf3f3f')],
    [prop('data-isvalid'), always('#a8c038')],
    [T, always(defaultColor)],
  ]),
)

export const RadioGroupContainer = styled.div`
  & > div > div > label {
    align-items: center;
    color: #4d5062;
    display: flex;
    margin-bottom: 14px;

    input {
      height: 0;
      opacity: 0;
      width: 0;

      &:checked + span:empty {
        background: radial-gradient(
          #65a300 0%,
          #65a300 40%,
          transparent 50%,
          transparent
        );
        border-color: #65a300;
      }
    }

    span:empty {
      border: 1px solid #9092a2;
      border-radius: 50%;
      display: block;
      height: 20px;
      margin-right: 14px;
      width: 20px;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 24px;
  min-width: 50px;
  position: relative;
  width: 100%;
`

export const Title = styled.label`
  color: ${loadColor('#4d5062')};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  width: 100%;
`

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 300;
  padding-left: 6px;
`

export const ErrorLabel = styled.label`
  color: #cf3f3f;
  font-size: 12px;
  margin-top: 10px;
  min-width: 60px;
`

export const Input = styled(FormInput)`
  & > div > div > input {
    background-color: #f9f9f9;
    border: 1px solid ${loadColor('#4d5062')};
    border-radius: 6px;
    box-sizing: border-box;
    height: 54px;
    padding: 16px;
    width: 100%;

    &:focus {
      border: 1px solid ${loadColor('#363845')};
      color: #363845;
    }
  }

  & > div > div > button {
    appearance: none;
    background-image: url(${visibilityOn});
    background-repeat: no-repeat;
    background-size: 25px 22px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 24px;
    margin-top: 17px;
    outline: none;
    position: absolute;
    right: 32px;
    width: 27px;
  }

  & > div > div > input[type='text'] + button {
    background-image: url(${visibilityOff});
  }
`

export const DropDown = styled(Select)`
  font-size: 14px;
  height: 54px;

  & > div:first-of-type {
    background-color: #f9f9f9;
    height: 100%;
  }

  & > div {
    background-color: #f9f9f9;
    border: 1px solid ${loadColor('#363845')} !important;
    color: #363845;
    margin-top: 1px;
  }
`

export const Tooltip = styled.div`
  margin-top: 55px;
  position: absolute;
  right: 15px;
`
