/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import media from 'styled-media-query'

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    width: calc(50% - 16px);
  }

  ${media.lessThan('large')`
      & > div {
          width: 100%;
      }
  `}
`
