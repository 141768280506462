import moment from 'moment'
import { isNil, join, pipe, reverse, split, unless } from 'ramda'

const getFormatedDate = date => moment(date).format()

const formatDate = pipe(
  unless(isNil, pipe(split('/'), reverse, join('-'), getFormatedDate)),
)

export default formatDate
