import { defaultTo } from 'ramda'

const {
  REACT_APP_API_ENVIRONMENT,
  REACT_APP_API_URL,
  REACT_APP_MIAW_CHAT_ID,
  REACT_APP_MIAW_CHAT_SCRT,
  REACT_APP_MIAW_CHAT_SRC,
  REACT_APP_MIAW_CHAT_TEAM,
  REACT_APP_PAGARME1_PLAN_ID,
  REACT_APP_PAGARME2_PLAN_ID,
  REACT_APP_RECAPTCHA_KEY,
} = process.env

const getApiUrl = defaultTo('https://stgapi.mundipagg.com/bigbang/v1')
const getPagarme1PlanId = defaultTo('plan_9kLJ7YvtOYS8nVWd')
const getPagarme2PlanId = defaultTo('plan_OrGWkKBsQ9spWjQJ')

const env = REACT_APP_API_ENVIRONMENT === 'live' ? 'live' : 'test'

const pagarme1PlanId = getPagarme1PlanId(REACT_APP_PAGARME1_PLAN_ID)
const pagarme2PlanId = getPagarme2PlanId(REACT_APP_PAGARME2_PLAN_ID)
const apiUrl = getApiUrl(REACT_APP_API_URL)
const recaptchaKey = REACT_APP_RECAPTCHA_KEY
const googleMarketingPlatform = {
  analyticsId: 'UA-44419105-1',
  offersPageExperimentId: 'myOl2GJpRhCMyB_ER6dekw',
  optimizeId: 'OPT-PH4QZJN',
}
const pagarme1SellerId = '5f0e0a8011d81f55b4933697'
const pagarmeApiUrl = 'https://api.pagar.me/1'

const chatMiaw = {
  id: REACT_APP_MIAW_CHAT_ID || '',
  scrt: REACT_APP_MIAW_CHAT_SCRT || '',
  src: REACT_APP_MIAW_CHAT_SRC || '',
  team: REACT_APP_MIAW_CHAT_TEAM || '',
}

export {
  apiUrl,
  googleMarketingPlatform,
  pagarme1PlanId,
  pagarme1SellerId,
  pagarme2PlanId,
  pagarmeApiUrl,
  recaptchaKey,
  chatMiaw,
}

export default env
