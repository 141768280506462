import cpfValidation from '../../validation/cpf'
import dateValidation from '../../validation/date'
import legalAgeValidation from '../../validation/legalAge'
import phoneValidation from '../../validation/phoneNumber'
import requiredValidation from '../../validation/required'

export const hasLegalAge = t => legalAgeValidation(t('validations.hasLegalAge'))
export const isCpf = t => cpfValidation(t('validations.isCpf'))
export const isDate = t => dateValidation(t('validations.isDate'))
export const isPhone = t => phoneValidation(t('validations.isPhone'))
export const isRequired = t => requiredValidation(t('errors.required_error'))
export const isUrl = t => requiredValidation(t('errors.required_error'))
export const isHttpsUrl = t => requiredValidation(t('errors.required_error'))
