import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './global.css'
import './initializeI18n'
import { initAmplitude } from './vendor/helpers/amplitude'

initAmplitude()

ReactDOM.render(<App />, document.getElementById('root'))
