import axios from 'axios'
import { Base64 } from 'js-base64'
import { apiUrl } from '../../environment'
import buildCompanyParameters from '../company/create/buildParameters'
import headers from '../headers'

const generateFingerprint = async (
  fingerprint,
  requestKey,
  body,
  hash,
  platformsBusinessVersion2,
) => {
  const result = Base64.decode(hash)
  const [merchId, accountId] = result.split('|')

  try {
    const fingerprintBody = {
      account_id: accountId,
      company_id: null,
      email: body.email,
      merchant_id: merchId,
      request_body: JSON.stringify(
        buildCompanyParameters(body, platformsBusinessVersion2),
      ),
      route: '/customers',
    }

    await axios.post(`${apiUrl}/fingerprint`, JSON.stringify(fingerprintBody), {
      headers: {
        requestid: fingerprint.requestId,
        RequestKey: requestKey,
        ...headers,
        visitorId: fingerprint.visitorId,
      },
    })
  } catch (err) {
    /* eslint-disable no-console */
    console.error(err)
  }
}

export default generateFingerprint
