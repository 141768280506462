import * as amplitude from '@amplitude/analytics-browser'

const isProduction = process.env.REACT_APP_API_ENVIRONMENT === 'production'

export const initAmplitude = () => {
  let apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY
  if (!apiKey) {
    apiKey = isProduction
      ? '6b7a94ea0ee04587c94bef246ea605d3'
      : 'd040130b5352bb78b148887a1a097ec3'
  }

  amplitude.init(apiKey, {
    flushIntervalMillis: 0,
    flushQueueSize: 1,
    transport: 'beacon',
  })
}

export const sendAmplitudeEvent = (eventName, eventProperties) =>
  amplitude.track({
    event_properties: {
      entity: 'autocred',
      feature: 'view',
      label: eventName,
      metadata: {
        env: isProduction ? 'production' : 'development',
        ...eventProperties,
      },
    },
    event_type: eventName,
  })
