import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Ul = styled.ul`
  color: #9092a2;
  display: flex;
  gap: 24px;

  ${media.greaterThan('large')`
    flex-direction: column;
  `}
`

export const Li = styled.li`
  display: flex;

  ${media.greaterThan('large')`
    height: 24px;
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0px;
    }
  `}
`

export const Button = styled.button(
  props => css`
    align-items: center;
    background: transparent;
    border: none;
    cursor: ${!props.disabled && 'pointer'};
    display: flex;
    font-size: 16px;
    padding: 0;
  `,
)

const getDesktopColor = ({ isCurrentItem, isPreviousItem }) => {
  if (isPreviousItem) {
    return '#595A63'
  }

  if (isCurrentItem) {
    return '#65A300'
  }

  return '#A8A8AD'
}

export const Bullet = styled.div(
  props => css`
    align-items: center;
    background-color: ${props.isCurrentItem || props.isPreviousItem
      ? '#6045AF'
      : '#C4C4C4'};
    border-radius: 48%;
    display: flex;
    height: ${props.isCurrentItem || props.isPreviousItem ? '24px' : '16px'};
    justify-content: center;
    width: ${props.isCurrentItem || props.isPreviousItem ? '24px' : '16px'};

    ${media.greaterThan('large')`
      background-color: ${
        props.isPreviousItem ? getDesktopColor(props) : 'transparent'
      };
      border: ${
        props.isPreviousItem ? 'none' : `4px solid ${getDesktopColor(props)}`
      };
      height: 24px;
      margin-right: 6px;
      width: 24px;
    `}
  `,
)

export const Span = styled.ul(
  props => css`
    display: none;
    margin-left: 10px;
    margin-top: 2px;
    padding-inline-start: 0px;
    color: ${getDesktopColor(props)}
    font-weight: bold;

    ${media.greaterThan('large')`
      display: unset;
    `}
  `,
)
