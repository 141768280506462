import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import ConfirmationButtons from '../ConfirmationButtons'

const Form = ({
  children,
  data,
  disableSubmit,
  onPreviousButton,
  onSubmit,
  submitTranslationPath,
  t,
}) => (
  <FinalForm
    initialValues={{ ...data }}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        {children}
        <ConfirmationButtons
          disableSubmit={disableSubmit}
          onPreviousButton={onPreviousButton}
          submitTranslationPath={submitTranslationPath}
          t={t}
        />
      </form>
    )}
  />
)

Form.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({}),
  disableSubmit: PropTypes.bool,
  onPreviousButton: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitTranslationPath: PropTypes.string,
  t: PropTypes.func.isRequired,
}

Form.defaultProps = {
  data: {},
  disableSubmit: false,
  onPreviousButton: null,
  submitTranslationPath: 'pages.continue',
}

export default Form
