import PropTypes from 'prop-types'
import React from 'react'
import * as S from './styles'

const ConfirmationButtons = ({
  disableSubmit,
  onPreviousButton,
  submitTranslationPath,
  t,
}) => (
  <S.ConfirmActions>
    {onPreviousButton && (
      <S.ReturnButton onClick={onPreviousButton} type="button">
        {t('pages.return')}
      </S.ReturnButton>
    )}
    <S.ContinueButton disabled={disableSubmit} type="submit">
      {t(submitTranslationPath)}
    </S.ContinueButton>
  </S.ConfirmActions>
)

ConfirmationButtons.propTypes = {
  disableSubmit: PropTypes.bool,
  onPreviousButton: PropTypes.func,
  submitTranslationPath: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

ConfirmationButtons.defaultProps = {
  disableSubmit: false,
  onPreviousButton: null,
}

export default ConfirmationButtons
