import styled from 'styled-components'
import media from 'styled-media-query'

export const FirstRow = styled.div`
  ${media.greaterThan('large')`
    width: 48%;
  `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 20px;

  & > input {
    flex: 1;
  }

  ${media.greaterThan('large')`
    flex-direction: row;
  `}
`
