/* eslint-disable sort-keys */
import { pagarme1PlanId, pagarme2PlanId } from '../../../environment'
import parseCurrencyStringToInteger from '../../../vendor/helpers/parseCurrencyStringToInteger'
import {
  date as formatDate,
  phone as formatPhoneNumber,
  toHttp,
  toNumerical,
} from '../../formatters'

const creationAt = new Date().toISOString()

const removeNonDigits = value => value.replace(/\D/g, '')

export const isPagarme2Platform = (platform, platformsBusinessVersion2) => {
  const platformSelect = platformsBusinessVersion2
    .map(pagarme2Platform => pagarme2Platform.value)
    .includes(platform)

  const pattern = /^outra -/i
  const patternDevelopment = platform.includes('Desenvolvimento próprio')
  const patternResult = pattern.test(platform)

  const isPagarme2 = patternResult || patternDevelopment || platformSelect

  return isPagarme2
    ? { planId: pagarme2PlanId, businessVersion: 'pagarme2' }
    : { planId: pagarme1PlanId, businessVersion: 'pagarme1' }
}

export const formatData = (
  data,
  platformsBusinessVersion2,
  companyCreationDate = creationAt,
) => {
  const { businessVersion, planId } = isPagarme2Platform(
    data.webPlatform,
    platformsBusinessVersion2,
  )

  return {
    subscription: {
      bank_account: {
        account_digit: data.bankAccountDv,
        account_number: removeNonDigits(data.bankAccount),
        branch: data.agency,
        branch_digit: data.agencyDv || null,
        code: data.bankCode,
        document_number: toNumerical(data.cnpj),
        name: data.legalName,
        type: data.bankAccountType,
      },
      plan_id: planId,
      payment_method: 'Boleto',
    },
    origin: 'self',
    platform: 'apimundipagg',
    name: data.tradeName,
    annual_revenue: parseCurrencyStringToInteger(data.annualBilling),
    corporate_name: data.legalName,
    integration: {
      maintainer: data.webPlatform || 'Desenvolvimento próprio',
      type:
        data.webPlatform === 'Desenvolvimento próprio'
          ? 'Desenvolvimento próprio'
          : 'Plataforma',
    },
    address: {
      street: data.companyStreet,
      number: data.companyNumber,
      neighborhood: data.companyNeighborhood,
      zip_code: toNumerical(data.companyCep),
      city: data.companyCity,
      state: data.companyState,
      country: 'BR',
      complement: data.companyComplement,
      reference_point: data.companyReferencePoint,
    },
    contacts: [
      {
        name: data.adminName,
        email: data.email,
        phones: [formatPhoneNumber(data.phoneNumber)],
        role: 'administrative',
      },
      {
        mother_name: data.motherName,
        birthday: formatDate(data.birthDate),
        cpf: toNumerical(data.cpf),
        monthly_income: parseCurrencyStringToInteger(
          data.partnerMonthlyBilling,
        ),
        professional_occupation: data.position,
        name: data.partnerName,
        email: data.partnerEmail,
        phones: [formatPhoneNumber(data.phone)],
        address: {
          street: data.partnerStreet,
          number: data.partnerNumber,
          neighborhood: data.partnerNeighborhood,
          zip_code: toNumerical(data.partnerCep),
          city: data.partnerCity,
          state: data.partnerState,
          country: 'BR',
          complement: data.partnerComplement,
          reference_point: data.partnerReferencePoint,
        },
        self_declared_legal_representative: data.isPartner === 'Sim',
        role: 'managing_partner',
      },
    ],
    document: {
      number: toNumerical(data.cnpj),
      type: 'CNPJ',
    },
    Facebook_Lead_ID: data.Facebook_Lead_ID
      ? data.Facebook_Lead_ID
      : 'b8eddf96-5912-4892-ac3d-216a466b4a4f',
    segment: {
      external: {
        primary_classification: 'Outros Serviços',
        secundary_classification: 'Outros Serviços - Outros',
      },
      support_type: 'Support',
    },
    metadata: data.metadata,
    terms_of_use: {
      accepted: true,
      id: companyCreationDate,
      user: data.email,
    },
    business_model: 'psp',
    business_version: businessVersion,
    web_site_url: toHttp(data.website),
  }
}

export default formatData
